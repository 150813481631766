<template>
  <div>
    <v-container>
      <v-row justify="space-between">
        <v-col cols="12" md="6">
          <h2 class="headline">{{ title }}</h2>
        </v-col>

        <v-col cols="12" md="6" align-self="end" class="text-right">
          <v-btn
            small
            outlined
            class="mr-4"
            :color="$store.state.secondaryColor"
            @click="goBack"
          >
            <v-icon small left>cancel</v-icon>
            <span class="caption text-capitalize font-weight-bold">{{
              $t("cancel")
            }}</span>
          </v-btn>
          <v-btn
            small
            dark
            class="gradients"
            :color="$store.state.secondaryColor"
            @click="updateStore"
            :loading="loading"
          >
            <v-icon small left>save</v-icon>
            <span class="caption text-capitalize font-weight-bold">{{
              $t("save changes")
            }}</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-snackbar top center v-model="snackbar">
        {{ $t("business details successfully updated") }}
        <template v-slot:action="{ attrs }">
          <v-btn
            :color="$store.state.secondaryColor"
            text
            v-bind="attrs"
            @click="snackbar = false"
            :timeout="timeout"
          >
            {{ $t("close") }}
          </v-btn>
        </template>
      </v-snackbar>

      <v-divider></v-divider>
      <v-alert>
        <v-form ref="form">
          <v-container>
            <!-- ################# -->
            <v-layout row wrap justify-space-around class="mb-3">
              <v-flex xs12 sm4 md4 lg4 class="text-center">
                <v-avatar tile size="150" color="grey lighten-2">
                  <v-img :src="register.logo" alt="business logo"></v-img>
                </v-avatar>
                <v-layout row wrap class="mt-2 mb-2">
                  <v-flex xs12 sm12 md12 lg12>
                    <span class="caption">{{ $t("business logo") }} *</span>
                    <input
                      type="file"
                      @change="updateLogo"
                      dense
                      small-chips
                      :rules="rules.emptyField"
                      class="caption mt-2"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm4 md4 lg4 class="text-center">
                <v-avatar tile size="150" color="grey lighten-2">
                  <v-img :src="register.banner" alt="business banner"></v-img>
                </v-avatar>

                <v-layout row wrap class="mt-2 mb-2">
                  <v-flex xs12 sm12 md12 lg12>
                    <span class="caption">{{ $t("business banner") }} *</span>
                    <input
                      type="file"
                      @change="updateBanner"
                      dense
                      small-chips
                      :rules="rules.emptyField"
                      class="caption mt-2"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <!-- ################### -->
            <v-layout row wrap class="mt-1 caption elevation-1">
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("business name") }} :
                </span>
                <v-text-field
                  outlined
                  dense
                  v-model="register.businessName"
                  :rules="rules.emptyField"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("abbreviation") }}:</span
                >
                <v-text-field
                  outlined
                  dense
                  v-model="register.abbreviation"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("type of business") }}:
                </span>
                <v-text-field
                  outlined
                  dense
                  v-model="register.businessType"
                  :rules="rules.emptyField"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("website") }}:
                </span>
                <v-text-field
                  outlined
                  dense
                  v-model="register.website"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap class="mt-4 caption elevation-1">
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("email") }}:
                </span>
                <v-text-field
                  outlined
                  dense
                  v-model="register.email"
                  :rules="rules.email"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("telephone") }}:
                </span>
                <v-text-field
                  outlined
                  dense
                  v-model="register.phone"
                  :rules="rules.emptyField"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("PO BOX") }}:
                </span>
                <v-text-field
                  outlined
                  dense
                  v-model="register.address.pobox"
                  :rules="rules.emptyField"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("registration number") }}:
                </span>
                <v-text-field
                  outlined
                  dense
                  v-model="register.regNo"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row wrap class="mt-4 caption elevation-1">
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("permit number") }}:
                </span>
                <v-text-field
                  outlined
                  dense
                  v-model="register.permitNo"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("pin") }}:
                </span>
                <v-text-field
                  outlined
                  dense
                  v-model="register.pin"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize">
                  {{ $t("bank name") }}:
                </span>
                <v-text-field
                  outlined
                  dense
                  v-model="register.bank.bankName"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("bank account number") }}:
                </span>
                <v-text-field
                  outlined
                  dense
                  v-model="register.bank.accountNo"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap class="mt-4 caption elevation-1">
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("mpesa till number") }}:
                </span>
                <v-text-field
                  outlined
                  dense
                  v-model="register.mpesa.tillNo"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("mpesa paybill number") }}:
                </span>
                <v-text-field
                  outlined
                  dense
                  v-model="register.mpesa.paybillNo"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize">
                  {{ $t("mpesa account number") }}:
                </span>
                <v-text-field
                  outlined
                  dense
                  v-model="register.mpesa.accountNo"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("glopay") }}:
                </span>
                <v-text-field
                  outlined
                  dense
                  v-model="register.glopay"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row wrap class="mt-4 caption elevation-1">
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("country") }}:
                </span>
                <v-combobox
                  v-model="register.address.country"
                  :items="countries"
                  @change="selectCountry"
                  :rules="rules.emptyField"
                  outlined
                  dense
                ></v-combobox>
              </v-flex>
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("county") }}:</span
                >
                <v-combobox
                  outlined
                  dense
                  v-model="register.address.county"
                  :items="provinces"
                  @change="selectCounty"
                  :rules="rules.emptyField"
                ></v-combobox>
              </v-flex>
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("city-town") }}:
                </span>
                <v-combobox
                  outlined
                  dense
                  :items="towns"
                  v-model="register.address.city"
                ></v-combobox>
              </v-flex>
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("district-territory") }}:
                </span>
                <v-combobox
                  outlined
                  dense
                  v-model="register.address.territory"
                  :items="territories"
                ></v-combobox>
              </v-flex>
            </v-layout>

            <v-layout row wrap class="mt-4 caption elevation-1">
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("estate") }}:
                </span>
                <v-text-field
                  outlined
                  dense
                  v-model="register.address.estate"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("street") }}:
                </span>
                <v-text-field
                  outlined
                  dense
                  v-model="register.address.city"
                  :rules="rules.emptyField"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("building") }}:
                </span>
                <v-text-field
                  outlined
                  dense
                  v-model="register.address.building"
                  :rules="rules.emptyField"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md3 lg3 class="px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("house-suite-number") }}:
                </span>
                <v-text-field
                  outlined
                  dense
                  v-model="register.address.no"
                  :rules="rules.emptyField"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-alert>
    </v-container>
  </div>
</template>

<script>
import db from "@/plugins/fb";
import fb from "firebase/app";
import countryList from "@/data/countries";
// import    { format }  from   'date-fns'
export default {
  data: () => ({
    title: "Update Business Details",
    register: "",
    dialog: false,
    snackbar: false,
    timeout: 2000,

    provinces: [],
    towns: [],

    territories: [],
    countryList,

    file: "",
    logo: "",
    banner: "",

    logoPreview:
      "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_960_720.png",
    bannerPreview:
      " https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_960_720.png",

    loading: false,
  }),

  computed: {
    logoName() {
      return this.register.businessName + "_logo";
    },

    countries() {
      return this.countryList.map((item) => item.country);
    },

    bannerName() {
      return this.register.businessName + "_banner";
    },
    rules() {
      return {
        email: [
          (v) => !!v || this.$t("e-mail is required"),
          (v) => /.+@.+\..+/.test(v) || this.$t("please enter a valid email"),
        ],
        emptyField: [(v) => !!v || this.$t("please fill out this field")],
      };
    },
  },

  created() {
    this.getStoreDetails();
  },

  methods: {
    getStoreDetails() {
      db.collection("businesses")
        .doc(this.$route.params.busId)
        .get()
        .then((doc) => {
          this.register = doc.data();
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },

    selectCountry() {
      let index = this.countryList.findIndex(
        (x) => x.country == this.register.address.country
      );
      let region = this.countryList[index].regions; //gives regions of selected country
      let provincesList = region.map((item) => item.province); //gives provinces of selected region
      this.provinces = provincesList; //set provinces in templates
    },

    selectCounty() {
      let index = this.countryList.findIndex(
        (x) => x.country == this.register.address.country
      );
      let region = this.countryList[index].regions; //gives regions of selected country
      let provincesList = region.map((item) => item.province); //gives provinces of selected region
      this.provinces = provincesList; //set provinces in templates

      let i = region.findIndex(
        (x) => x.province == this.register.address.county
      );
      this.towns = region[i].towns;
      this.territories = region[i].territories;
    },

    updateLogo(event) {
      this.logo = event.target.files[0];
      this.register.logo = URL.createObjectURL(this.logo);
    },

    updateBanner(event) {
      this.banner = event.target.files[0];
      this.register.banner = URL.createObjectURL(this.banner);
    },

    uploadBanner(id) {
      let storageRef = fb
        .storage()
        .ref("businesses/banners/" + this.bannerName);
      let uploadTask = storageRef.put(this.banner);

      uploadTask.on(
        "state_changed",
        () => {},
        (error) => {
          error.preventDefault();
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadLink) => {
            db.collection("businesses")
              .doc(id)
              .update({
                banner: downloadLink,
              })
              .then(() => {
                if (this.logo != "") this.uploadLogo(id);
              });
          });
        }
      );
    },

    uploadLogo(id) {
      let storageRef = fb.storage().ref("businesses/logos/" + this.logoName);
      let uploadTask = storageRef.put(this.logo);

      uploadTask.on(
        "state_changed",
        () => {},
        (error) => {
          error.preventDefault();
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadLink) => {
            db.collection("businesses")
              .doc(id)
              .update({
                logo: downloadLink,
              })
              .then(() => {
                this.loading = false;
              });
          });
        }
      );
    },

    updateStore() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.banner != "") {
          this.uploadBanner(this.$route.params.busId);
          db.collection("businesses")
            .doc(this.$route.params.busId)
            .update(this.register)
            .then(() => {
              this.loading = false;
              this.snackbar = true;
              setTimeout(() => {
                window.history.back();
              }, 2000);
            })
            .catch((error) => {
              console.error("Error updating document: ", error);
            });
        } else {
          db.collection("businesses")
            .doc(this.$route.params.busId)
            .update(this.register)
            .then(() => {
              this.loading = false;
              this.snackbar = true;
              setTimeout(() => {
                window.history.back();
              }, 2000);
            })
            .catch((error) => {
              console.error("Error updating document: ", error);
            });
        }
      }
    },

    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
